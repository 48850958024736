import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { useToggle } from '@shared/hooks/useToggle';
import { useEffect } from 'react';

export const usePaymentCTAModalLogic = () => {
    const { currentSeason } = useCurrentSeason();
    const { data: farmPaymentStatus } = useGetFarmPaymentStatusQuery({ farmId: currentSeason?.farm_id });

    const hasToPayForBaseline = !!(farmPaymentStatus?.status === 'deferred' && currentSeason?.has_results_published);

    const hasToRenew = !!(farmPaymentStatus?.status === 'to_be_renewed' && currentSeason?.has_results_published);

    const showModal = hasToPayForBaseline || hasToRenew;

    const {
        open: paymentCTAModalOpen,
        handleOpen: onPaymentCTAModalOpen,
        handleClose: onPaymentCTAModalClose,
    } = useToggle();

    useEffect(() => {
        if (showModal) {
            onPaymentCTAModalOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return {
        paymentCTAModalOpen,
        onPaymentCTAModalOpen,
        onPaymentCTAModalClose,
        renewal: hasToRenew,
    };
};
