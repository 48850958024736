import { useDuplicateOperationsFromYearMutation, yoyApi } from '@modules/yoy/api/yoyApi';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { useState } from 'react';

export const useDuplicateOperationsFromYear = ({
    onSuccess,
    onError,
}: { onSuccess?: () => void; onError?: () => void } = {}) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [duplicate] = useDuplicateOperationsFromYearMutation();
    const [lazyGetProgress] = entities.progress.useLazyGet();
    const [lazyGetFieldCropsWithUntouchedDuplicatedITK] = yoyApi.useLazyGetFieldCropsWithUntouchedDuplicatedITKQuery();
    const [isDuplicating, setIsDuplicating] = useState(false);

    const duplicateFromYear = async ({ seasonId, cropIds }: { seasonId: number; cropIds: number[] }) => {
        try {
            setIsDuplicating(true);
            await duplicate({
                farmSeasonId: currentSeasonId,
                body: { source_season_id: seasonId, crop_ids: cropIds },
            }).unwrap();
            await Promise.all([
                lazyGetProgress({ seasonId: currentSeasonId }),
                lazyGetFieldCropsWithUntouchedDuplicatedITK({ farmSeasonId: currentSeasonId }),
            ]);
            setIsDuplicating(false);
            onSuccess?.();
        } catch (e) {
            setIsDuplicating(false);
            onError?.();
        }
    };

    return {
        duplicateFromYear,
        isLoading: isDuplicating,
    };
};
