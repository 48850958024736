import { HelpBox, Modal } from '@soil-capital/ui-kit';
import { IconLightning, IconWarning } from '@soil-capital/ui-kit/icons';
import { Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useYoyDuplicationOverwriteModalStyles } from './useYoyDuplicationOverwriteModal.style';

export const YoyDuplicationOverwriteModal = ({
    open,
    onClose,
    onConfirm,
    isDuplicating = false,
    harvestYear,
    selectedCropsHaveDuplication = false,
}: {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    isDuplicating?: boolean;
    harvestYear: number;
    selectedCropsHaveDuplication?: boolean;
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { classes } = useYoyDuplicationOverwriteModalStyles();

    return (
        <Modal
            size="medium"
            open={open}
            onClose={onClose}
            onConfirm={onConfirm}
            header={
                <Modal.Header>
                    <Typography variant="h3">{t('yoy.duplication.modal.overwrite.title', { harvestYear })}</Typography>
                    {!isDuplicating && <Modal.Close />}
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    {selectedCropsHaveDuplication && (
                        <HelpBox>{t('yoy.duplication.modal.overwrite.help-box', { harvestYear })}</HelpBox>
                    )}
                    <HelpBox className={selectedCropsHaveDuplication ? classes.sprayingHelpBox : ''}>
                        {t('yoy.duplication.modal.overwrite.help-box-no-spraying', { harvestYear })}
                    </HelpBox>
                    <Typography
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: theme.spacing(1),
                            marginLeft: theme.spacing(1),
                            marginRight: theme.spacing(1),
                            marginTop: theme.spacing(2),
                        }}
                    >
                        <IconWarning />
                        {t('yoy.duplication.modal.warning')}
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel disabled={isDuplicating} onClick={onClose}>
                        {t('constants.cancel')}
                    </Modal.Cancel>
                    <Modal.Confirm loading={isDuplicating}>
                        <IconLightning />
                        <Typography>{t('yoy.duplication.modal.overwrite.confirm')}</Typography>
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
