import { paymentRoutesConfig } from './payment.routes';
import { Navigate, useRoutes } from 'react-router-dom';
import { Pricing } from './pages/Pricing/Pricing';
import { BillingInfo } from './pages/BillingInfo/BillingInfo';
import { NetOff } from './pages/NetOff/NetOff';
import { Processing } from './pages/Processing/Prossecing';
import { Confirmation } from './pages/Confirmation/Confirmation';
import PaymentPageLayout from '@shared/components/PaymentPage/PaymentPageLayout';
import { usePaymentLogic } from './usePaymentLogic';

export default () => {
    const { hasPermission, currentSeasonId, isLoading, canMakePayment } = usePaymentLogic();

    if (!isLoading && (!hasPermission || !canMakePayment)) {
        // disable the redirection on confirmation pages & payment processing pages to avoid redirection if farm payment status has already changed
        if (
            !location.pathname.includes('confirmation') &&
            !location.pathname.includes('net-off') &&
            !location.pathname.includes('processing')
        ) {
            return <Navigate to={`/${currentSeasonId}`} replace />;
        }
    }

    const routes = useRoutes([
        {
            path: paymentRoutesConfig.PRICING,
            element: <Pricing />,
        },
        {
            path: paymentRoutesConfig.BILLING,
            element: <BillingInfo />,
        },
        {
            path: paymentRoutesConfig.NETOFF,
            element: <NetOff />,
        },
        {
            path: paymentRoutesConfig.INPROGRESS,
            element: <Processing />,
        },
        {
            path: paymentRoutesConfig.FAILED,
            element: <Processing failedPayment />,
        },
        {
            path: paymentRoutesConfig.CONFIRMATION,
            element: <Confirmation />,
        },
        { path: '/', element: <Navigate to={paymentRoutesConfig.PRICING} replace />, children: [] },
    ]);

    return <PaymentPageLayout isLoading={isLoading}>{routes}</PaymentPageLayout>;
};
