import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { TechnicalItineraryCropList } from '@modules/encoding/modules/technicalItinerary/components/TechnicalItineraryCropList/TechnicalItineraryCropList';
import { TechnicalItineraryHelpBox } from '@modules/encoding/modules/technicalItinerary/components/TechnicalItineraryHelpBox/TechnicalItineraryHelpBox';
import { useTranslation } from 'react-i18next';
import { useIntroductionContentData } from '@modules/encoding/modules/technicalItinerary/pages/IntroductionContentPage/useIntroductionContentData';
import { useYoyDuplicationForm } from '@modules/yoy/hooks/useYoyDuplicationForm';
import { Box } from '@soil-capital/ui-kit/material-core';
import { FormProvider } from 'react-hook-form';
import { YoyDuplicationAccordion } from '@modules/yoy/components/YoyDuplicationAccordion/YoyDuplicationAccordion';
import { YoyDuplicationCropList } from '@modules/yoy/components/YoyDuplicationCropList/YoyDuplicationCropList';
import { YoyDuplicationButton } from '@modules/yoy/components/YoyDuplicationButton/YoyDuplicationButton';
import { useYoyDuplicationModule } from '@modules/yoy/hooks/useYoyDuplicationModule';
import { EncodingDoneButton } from '@modules/encoding/shared/components/EncodingDoneButton/EncodingDoneButton';

export const YoyDuplicationPage = () => {
    const { t } = useTranslation();
    const {
        harvestYear,
        hasStartedItinerary,
        isDataLoading,
        allFieldsCompleted,
        handleStepRedirect,
        handleContinueClick,
    } = useIntroductionContentData();

    const { isModuleOpened, toggleModule } = useYoyDuplicationModule();
    const { methods, onSubmit, isDuplicating } = useYoyDuplicationForm();

    const handleSubmit = async () => {
        await onSubmit();
        toggleModule();
    };

    return (
        <FormProvider {...methods}>
            <ContentPage
                Title={
                    <ContentPage.Title>
                        {t('encoding-technical-itinerary.introduction.title', { harvestYear })}
                    </ContentPage.Title>
                }
                Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
                Action={
                    <EncodingDoneButton
                        fallbackButton={
                            <ContentPage.Action
                                onClick={allFieldsCompleted ? handleStepRedirect : handleContinueClick}
                                disabled={isDuplicating}
                            >
                                {hasStartedItinerary ? t('constants.continue') : t('constants.start')}
                            </ContentPage.Action>
                        }
                        disabled={isDuplicating}
                    />
                }
                isLoading={isDataLoading}
                Footer={
                    isModuleOpened ? (
                        <ContentPage.Footer>
                            <YoyDuplicationButton onConfirm={handleSubmit} isLoading={isDuplicating} />
                        </ContentPage.Footer>
                    ) : undefined
                }
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, background: 'white' }}>
                    <YoyDuplicationAccordion open={isModuleOpened} onClick={toggleModule} />
                    {isModuleOpened ? (
                        <YoyDuplicationCropList />
                    ) : (
                        <IntroProgressLayout
                            title={t('encoding-technical-itinerary.introduction.progress-title')}
                            leftSection={<TechnicalItineraryCropList />}
                            rightSection={<TechnicalItineraryHelpBox />}
                        />
                    )}
                </Box>
            </ContentPage>
        </FormProvider>
    );
};
