import { useNavigate } from 'react-router-dom';
import entities from '@shared/entities';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { getHomePageStatus } from './getHomePageStatus';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { enqueueSnackbar } from 'notistack';
import { useStartNewSeason } from '@modules/yoy/hooks/useStartNewSeason';
import { useTranslation } from 'react-i18next';
import { useCanOpenNewSeason } from '@shared/hooks/useCanOpenNewSeason';
import { useEffect } from 'react';
import seasonApi from '@shared/entities/season/season.api';
import { auth } from '@modules/auth';
import { STATIC_ROLE_SLUGS } from '@modules/auth/api/authApi.types';

export const useHomePageMessage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userRole = auth.useAuthUser()?.role?.name;

    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();

    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();

    const { data: farmPaymentStatus, isLoading: isLoadingPaymentStatus } = useGetFarmPaymentStatusQuery({
        farmId: currentSeason?.farm_id,
    });

    const [markResultsMsgAsSeen] = seasonApi.useMarkResultsMsgAsSeen();

    const { canOpenNewSeason, isLoading: isLoadingCanOpenNewSeason } = useCanOpenNewSeason();
    const carbonProgrammeYear = currentSeason?.carbon_programme_year;
    const isLoading =
        currentSeasonLoading || progressState.isLoading || isLoadingPaymentStatus || isLoadingCanOpenNewSeason;

    const handleClick = (path: string) => navigate(path);

    const homePageStatus = getHomePageStatus({
        farmSeasonStatus: currentSeason?.status,
        farmPaymentStatus: farmPaymentStatus?.status,
        isYoY: carbonProgrammeYear ? carbonProgrammeYear > 0 : false,
        hasSeenResultsAvailableMsg: !!currentSeason?.has_seen_results_available_msg,
    });

    const { startNewSeason, isLoading: isStartingNewSeason } = useStartNewSeason({
        onSuccess: (nextFarmSeasonId: number) => {
            navigate(`/${nextFarmSeasonId}/farm-profile`);
        },
    });

    const handleConfirm = async () => {
        if (!currentSeason) return;
        try {
            await startNewSeason(currentSeason.id);
        } catch (e) {
            enqueueSnackbar(t('yoy.season-opening.error'), { variant: 'error' });
            return;
        }
    };

    const getMessage = () => {
        if (homePageStatus === 'SELF_ENCODING_NOT_STARTED_BASELINE')
            return {
                title: 'homepage.messages.ready-to-start.title',
                description: 'homepage.messages.ready-to-start.description',
                action: {
                    label: 'homepage.messages.ready-to-start.cta',
                    link: () => handleClick(`/${currentSeasonId}/${encodingRoutesConfig.PREPARATION}`),
                    isLoading: isStartingNewSeason,
                },
            };

        if (homePageStatus === 'SELF_ENCODING_NOT_STARTED_YOY')
            return {
                title: 'homepage.messages.ready-to-start.title',
                description: 'homepage.messages.ready-to-start-yoy.description',
                action: {
                    label: 'homepage.messages.ready-to-start.cta',
                    link: canOpenNewSeason
                        ? handleConfirm
                        : () => handleClick(`/${currentSeasonId}/${encodingRoutesConfig.PREPARATION}`),
                    isLoading: isStartingNewSeason,
                },
            };

        if (homePageStatus === 'SELF_ENCODING_ONGOING') {
            const pathToRedirectTo =
                nextStepToComplete && getRouteMatchingStep(nextStepToComplete?.slug)
                    ? getRouteMatchingStep(nextStepToComplete?.slug)
                    : null;

            return {
                title: 'homepage.messages.encoding-pending.title',
                description: 'homepage.messages.encoding-pending.description',
                action: {
                    label: 'homepage.messages.encoding-pending.cta',
                    link: () =>
                        handleClick(`/${currentSeasonId}/${pathToRedirectTo ?? encodingRoutesConfig.PREPARATION}`),
                },
            };
        }
        if (homePageStatus === 'DATA_VERIFICATION') {
            return {
                title: 'homepage.messages.self-encoding-done.title',
                description: 'homepage.messages.self-encoding-done.description',
                descriptionValues: {
                    year: currentSeason?.harvest_year,
                },
            };
        }
        if (homePageStatus === 'WAITING_FOR_RESULTS') {
            return {
                title: 'homepage.messages.results-being-reviewed.title',
                description: 'homepage.messages.results-being-reviewed.description',
            };
        }

        if (homePageStatus === 'RESULTS_PUBLISHED_FIRST_CONNEXION') {
            return {
                title: 'homepage.messages.results-ready.title',
                description: 'homepage.messages.results-ready.description',
                action: {
                    label: 'homepage.messages.results-ready.cta',
                    link: () => handleClick(`/${currentSeasonId}/results/earning`),
                },
            };
        }

        if (homePageStatus === 'RESULTS_PUBLISHED_DEFERRED_PAYMENT') {
            return {
                title: 'homepage.messages.deferred-payment.title',
                description: 'homepage.messages.deferred-payment.description',
                action: {
                    label: 'homepage.messages.deferred-payment.cta',
                    link: () => handleClick(`/${currentSeasonId}/payment/pricing`),
                },
            };
        }

        if (homePageStatus === 'RESULTS_PUBLISHED_RENEWAL_PAYMENT') {
            return {
                title: 'homepage.messages.renewal.title',
                description: 'homepage.messages.renewal.description',
                action: {
                    label: 'homepage.messages.renewal.cta',
                    link: () => handleClick(`/${currentSeasonId}/payment/pricing`),
                },
            };
        }

        return {
            title: '',
            description: '',
        };
    };

    const message = getMessage();

    useEffect(() => {
        if (homePageStatus === 'RESULTS_PUBLISHED_FIRST_CONNEXION' && userRole === STATIC_ROLE_SLUGS.FARMER) {
            markResultsMsgAsSeen({ farmSeasonId: currentSeasonId });
        }
    }, [homePageStatus, markResultsMsgAsSeen, currentSeasonId, userRole]);

    return { isLoading, message, handleClick };
};
