import { makeStyles } from '@soil-capital/ui-kit/style';
import { IntroProgressItemStylePropsT } from './IntroProgressItem.types';

const borderWidth = '2px';
export default makeStyles<IntroProgressItemStylePropsT>()((theme, { hasSrcImage }) => ({
    introProgressItem: {
        borderRadius: `calc(${theme.shape.borderRadiusS}px + ${borderWidth})`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(1),

        // hover item behavior
        border: `2px solid transparent`,
        cursor: 'pointer',
        userSelect: 'none',
        transitionDuration: `${theme.transitions.duration.short}ms`,
        transitionTimingFunction: theme.transitions.easing.easeOut,
        '&:hover': {
            backgroundColor: theme.palette.primaryScale[100],
            border: `${borderWidth} solid ${theme.palette.primaryScale[300]}`,
        },
        minHeight: '40px',
        paddingLeft: hasSrcImage ? 0 : theme.spacing(1),
        background: 'none',
        color: 'inherit',
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        font: 'inherit',
        outline: 'inherit',
        width: '100%',
    },
    textAndIconContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    textContainer: {
        textAlign: 'left',
        display: 'flex',
        flex: 1,
        minWidth: 0,
        flexDirection: 'column',
        '&:span:first-of-type': {
            marginLeft: theme.spacing(1),
        },
    },
    collapseContainer: {
        marginLeft: theme.spacing(hasSrcImage ? 11 : 4),
    },
    progressContainer: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: 0,
    },
    progressIcon: {
        width: '36px',
    },
    progressText: {
        whiteSpace: 'nowrap',
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    disabled: {
        cursor: 'not-allowed',
    },
}));
