import { Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { Button } from '@soil-capital/ui-kit';
import { useHomePageStyles } from './HomePage.style';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';
import { useHomePageMessage } from './useHomePageMessage';
import { Trans } from 'react-i18next';

export const HomePageMessage = () => {
    const { isLoading, message } = useHomePageMessage();
    const { t } = useTranslation();
    const { classes } = useHomePageStyles();

    if (isLoading)
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Skeleton variant="text" width={150} height={20} sx={{ marginTop: 3 }} />
                <Skeleton variant="text" width={750} height={20} />
                <Skeleton variant="text" width={450} height={20} />
                <Skeleton variant="rounded" width={150} height={50} sx={{ marginTop: 3 }} />
            </div>
        );

    return (
        <>
            <Typography className={classes.messageTitle} variant="body">
                {t(message.title)}
            </Typography>
            <Typography className={classes.messageDescription} variant="body2">
                <Trans
                    i18nKey={message.description}
                    values={message.descriptionValues ? message.descriptionValues : ''}
                />
            </Typography>
            {message.action && (
                <Button
                    variant="contained"
                    size="large"
                    onClick={message.action.link}
                    endIcon={<IconArrowRight />}
                    sx={{ zIndex: 4 }}
                    loading={message.action.isLoading}
                >
                    {t(message.action.label)}
                </Button>
            )}
        </>
    );
};
